import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "/src/components/layout"

export default function NotFound() {
  return (
    <Layout>
      <div className="my-24 bg-white flex flex-col lg:relative">
        <div className="flex-grow flex flex-col">
          <main className="flex-grow flex flex-col bg-white">
            <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                <p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">404 error</p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
                <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-6">
                  <a href="/" className="text-base font-medium text-blue-600 hover:text-blue-500">
                    Go back home<span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">            
          <StaticImage
            className="absolute inset-0 h-full w-full object-cover rounded-lg"
            src="../images/404cow.jpg"
            alt="A close up of a lone cow standing in a field staring directly into the camera"
          />
        </div>
      </div>
    </Layout>
  )
}
